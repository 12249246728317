<template>
  <div :class="[background, {'i-border' : !hero}]">
    <b-container fluid class="i-skeleton">
      <b-row class="section"
             :class="[hero && !isDesktop ? 'hero-section-flex' : '', { 'hero-section' : hero }]"
      >
        <b-col v-if="!smallPhoto"
               :order="hero ? '1' : '0'"
               :order-md="photoRightAlign ? '1' : '0'"
               cols="12" md="6"
               :class="hero ? 'h-100' : 'photo-section'"
               class="text-left px-0">
          <video v-if="video"
                 :src="require(`@/assets/videos/${imagePath}`)"
                 loop
                 autoplay
                 preload="auto"
                 :class="video & smallPhoto ? 'small-section-img' : 'section-img'"
          />
<!--          <picture v-else>-->
<!--            <source media="(max-width=475px)"-->
<!--                    :srcset="imagePathSmall">-->
<!--            <source media="(min-width: 476px)"-->
<!--                    :srcset="imagePath">-->
<!--            <b-img-lazy-->
<!--                :src="imagePath"-->
<!--                class="section-img"-->
<!--                :alt="alt"-->
<!--                :class="[align, 'background-'+ imgBackground, 'hero-' + heroLargePosition, {'hero-image': hero}]"-->
<!--            />-->
<!--          </picture>-->
          <b-img-lazy
              :src="imagePath"
              v-else
              class="section-img"
              :alt="alt"
              :class="[align, 'background-'+ imgBackground, 'hero-' + heroLargePosition, {'hero-image': hero}]"
          />
        </b-col>
        <b-col v-else
               :order="hero ? '1' : '0'"
               :order-md="photoRightAlign ? '1' : '0'"
               cols="12"
               md="6"
               :class=" [hero && !isDesktop  ? 'background-reverse-gradient' : 'background-'+ imgBackground, hero ? 'mt-0': (imgBackground === 'none') ? 'py-md-0' : 'py-md-0']"
               class="px-0 align-items-center small-photo-section d-flex">
          <b-row
              class="mx-auto h-100"
              :class=" imgBackground !== 'none' ?
          'justify-content-center' :
          (photoRightAlign ? 'justify-content-start' : 'justify-content-end')">
            <b-col cols="12" lg="11" xl="10" class="text-center d-flex h-100">
              <video v-if="video"
                     :src="require(`@/assets/videos/${imagePath}`)"
                     loop
                     autoplay
                     preload="auto"
                     :class="video & smallPhoto ? 'small-section-img' : 'section-img'"
              />
<!--              <picture v-else>-->
<!--                <source media="(max-width: 767px)"-->
<!--                        :srcset="imagePathSmall">-->
<!--                <source media="(min-width: 768px)"-->
<!--                        :srcset="imagePath">-->
<!--                <b-img-lazy-->
<!--                    :src="imagePath"-->
<!--                    :alt="alt"-->
<!--                    class="small-section-img my-auto"-->
<!--                    :class="{ 'round-border' : roundRadius }"-->
<!--                />-->
<!--              </picture>-->
              <b-img-lazy
                  v-else
                  :src="imagePath"
                  :alt="alt"
                  class="small-section-img my-auto"
                  :class="{ 'round-border' : roundRadius }"
              />
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="12" md="6" class="my-md-auto i-outer-padding i-vertical-half-photo-text"
               :class="[hero && !isDesktop ? 'mt-3 mt-sm-4 mb-2 py-0 hero-text' : '']"
        >
          <b-row :class="photoRightAlign ? 'justify-content-end' : 'justify-content-left'">
            <b-col cols="12" :lg="hero ? '12' : '10'" :xl="hero ? '12' : '9'">
              <div v-if="hero">
                <h1 class="text-uppercase">
                  <slot name="header"></slot>
                </h1>
                <div class="mt-3 i-font-text-light i-sub-header i-secondary">
                  <slot name="content"></slot>
                </div>
                <div>
                  <b-img
                      v-if="hero && !isDesktop"
                      src="https://ik.imagekit.io/isotronic/general/hero-arrow_e77jOyXk9Li.svg?updatedAt=1631207539399"
                      alt="arrow"
                      height="57"
                      class="hero-mobile-arrow"
                      @click="clickArrow()"
                  ></b-img>
                </div>
              </div>
              <div v-else>
                <h2 class="text-uppercase">
                  <slot name="header"></slot>
                </h2>
                <div class="mt-3 i-font-text-light">
                  <slot name="content"></slot>
                </div>
              </div>
            </b-col>
            <b-col cols="12" :lg="hero ? '12' : '10'" :xl="hero ? '12' : '9'">
              <div class="mt-4" v-if="buttonLink">
                <callout-button :link="buttonLink">
                  <slot name="callout"></slot>
                </callout-button>
              </div>
              <div class="mt-4 h1" v-if="arrow && isDesktop"

              >
                <b-img
                    src="https://ik.imagekit.io/isotronic/general/hero-arrow_e77jOyXk9Li.svg?updatedAt=1631207539399"
                    alt="arrow"
                    height="57"
                    @click="clickArrow"
                    class="hero-arrow"
                ></b-img>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import CalloutButton from "@/components/utility/CalloutButton"
import {mapState} from "vuex";

export default {
  components: {
    CalloutButton
  },
  methods: {
    clickArrow() {
      this.$scrollTo('#' + this.scrollTo)
    }
  },
  computed: mapState([
    'isDesktop'
  ]),
  props: {
    imgAlt: String,
    calloutRoute: String,
    imagePath: String, //path to image in image kit
    // imagePathSmall: String,
    alt: String,
    scrollTo: String,
    buttonLink: { //link to call out button
      type: String,
      default: null
    },
    arrow: { // arrow at bottom of container displayed
      type: Boolean,
      default: false
    },
    hero: { //whether this is hero banner or not - larger text
      type: Boolean,
      default: false
    },
    photoRightAlign: { //place photo on left or right side of page
      type: Boolean,
      default: false
    },
    align: { //c - center, tr - top right, br - bottom right, bl - bottom left, tl - top left, bottom - bottom
      type: String,
      default: "center"
    },
    background: {
      type: String,
      default: 'white' // white / light
    },
    imgBackground: {
      type: String,
      default: 'none' //solid / gradient / reverse-gradient / grey
    },
    smallPhoto: { // photo is smaller than half screen with background
      type: Boolean,
      default: false
    },
    roundRadius: {
      type: Boolean,
      default: false
    },
    heroLargePosition: { //bottom, left
      type: String,
      default: "none"
    },
    video: {
      type: Boolean,
      default: false
    },
  }
}
</script>

<style lang="sass" scoped>
.hero-mobile-arrow
  position: absolute
  left: 15px
  top: 120%
  display: none
  @media screen and (min-width: $sm)
    display: block

.hero-arrow, .hero-mobile-arrow
    &:hover
      cursor: pointer

.hero-section
  @media screen and (min-width: $sm)
    min-height: calc(95vh - 72px) !important
    border-bottom: none !important

.hero-section-flex
  display: flex
  flex-direction: column

.hero-image
  max-height: 510px
  @media screen and (min-width: $md)
    max-height: none

.hero-text
  flex-grow: 1

.section
  //max-height: 975px
  overflow: hidden
  @media screen and (min-width: $sm)
    max-height: 1235px
  @media screen and (min-width: $md)
    max-height: none
    height: 700px
  @media screen and (min-width: $lg)
    height: 750px
  @media screen and (min-width: $xl)
    height: 870px

.photo-section
  max-height: 400px
  overflow: hidden
  @media screen and (min-width: $sm)
    max-height: 510px
  @media screen and (min-width: $md)
    max-height: none
    height: 700px
  @media screen and (min-width: $lg)
    height: 750px
  @media screen and (min-width: $xl)
    height: 870px

.section-img
  height: 100% !important
  width: 100% !important
  object-fit: cover

.small-section-img
  max-height: 400px
  max-width: 100% !important
  overflow: hidden
  margin: 70px 0 15px !important
  @media screen and (min-width: $sm)
    max-height: 510px
    margin-left: 30px!important
    margin-right: 30px!important
  @media screen and (min-width: $md)
    max-height: 100%
    margin-top: auto!important
    margin-bottom: auto!important

.hero-bottom
  object-position: bottom !important
  object-fit: contain !important

.hero-left
  object-position: left !important
  object-fit: contain !important
  @media screen and (min-width: $md)
    object-fit: cover !important
  @media screen and (min-width: $xl)
    object-fit: contain !important

.small-photo-section
  //min-height: 400px
  overflow: hidden
  mix-blend-mode: darken!important
  @media screen and (min-width: $sm)
    min-height: 510px
  @media screen and (min-width: $md)
    height: 100%
    max-height: none
    top: 50%
    transform: translateY(-50%)

.round-border
  border-radius: 50px

.bottom
  object-position: bottom

.tr
  @media screen and (min-width: $md)
    object-position: right top

.tl
  @media screen and (min-width: $md)
    object-position: left top

.bl
  @media screen and (min-width: $md)
    object-position: left bottom

.br
  @media screen and (min-width: $md)
    object-position: right bottom

.white
  background-color: white

.light
  background-color: $light-background

.background-grey
  background: #646568

.background-solid
  background: rgba(224, 90, 90, 0.8)

.background-gradient
  background: linear-gradient(0deg, rgba(235, 110, 111, 0) 0%, rgba(235, 110, 111, 0.0086472) 6.67%, rgba(235, 110, 111, 0.03551) 13.33%, rgba(235, 110, 111, 0.0816599) 20%, rgba(235, 110, 111, 0.147411) 26.67%, rgba(235, 110, 111, 0.231775) 33.33%, rgba(235, 110, 111, 0.331884) 40%, rgba(235, 110, 111, 0.442691) 46.67%, rgba(235, 110, 111, 0.557309) 53.33%, rgba(235, 110, 111, 0.668116) 60%, rgba(235, 110, 111, 0.768225) 66.67%, rgba(235, 110, 111, 0.852589) 73.33%, rgba(235, 110, 111, 0.91834) 80%, rgba(235, 110, 111, 0.96449) 86.67%, rgba(235, 110, 111, 0.991353) 93.33%, #EB6E6F 100%)

.background-reverse-gradient
  background: linear-gradient(180deg, rgba(235, 110, 111, 0) 0%, rgba(235, 110, 111, 0.0086472) 6.67%, rgba(235, 110, 111, 0.03551) 13.33%, rgba(235, 110, 111, 0.0816599) 20%, rgba(235, 110, 111, 0.147411) 26.67%, rgba(235, 110, 111, 0.231775) 33.33%, rgba(235, 110, 111, 0.331884) 40%, rgba(235, 110, 111, 0.442691) 46.67%, rgba(235, 110, 111, 0.557309) 53.33%, rgba(235, 110, 111, 0.668116) 60%, rgba(235, 110, 111, 0.768225) 66.67%, rgba(235, 110, 111, 0.852589) 73.33%, rgba(235, 110, 111, 0.91834) 80%, rgba(235, 110, 111, 0.96449) 86.67%, rgba(235, 110, 111, 0.991353) 93.33%, #EB6E6F 100%)

</style>
