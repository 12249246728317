<template>
  <router-link :to="$i18nRoute({ name: link})" class="link-styling">
    <nobr>
      <span class="first-link-part">
        <slot></slot>
      </span>
      <b-icon-arrow-up-right/>
    </nobr>
  </router-link>
</template>

<script>

export default {
  props: {
    link: String
  }
}
</script>

<style lang="sass" scoped>
.link-styling
  color: $primary-color
  text-decoration: none

.first-link-part
  display: inline-block
  &:hover
    text-decoration: underline
</style>
