<template>
  <div class="products">
    <b-container fluid class="p-0 i-skeleton">
      <b-row class="py-5">
        <b-col>
          <h2 class="text-uppercase text-center i-sub-header i-ternary pb-3">
            {{ $t("footer.products.header") }}
          </h2>
          <b-row class="justify-content-center">
            <b-col cols="auto"
                   v-for="(product, index) in products"
                   :key="product.name + index"
                   class="text-center py-2 py-sm-0 px-2 px-md-3"
            >
              <router-link tag="span"
                           :to="$i18nRoute({ name: product.page})">
                <product-square
                    :product="product.img"
                    :alt="product.alt"
                    class="mx-auto"
                >
                  {{ product.name }}
                </product-square>
              </router-link>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import ProductSquare from "@/components/footer/ProductSquare";

export default {
  components: {
    ProductSquare
  },
  data() {
    return {
      products: [
        {
          name: this.$t('footer.products.vials.name'),
          alt: this.$t('footer.products.vials.alt'),
          img: 'https://ik.imagekit.io/isotronic/homepage/products/vials_yYVKoVkpnQ.png?updatedAt=1635420829506',
          page: "Vials"
        },
        {
          name: this.$t('footer.products.ampoules.name'),
          alt: this.$t('footer.products.ampoules.alt'),
          img: 'https://ik.imagekit.io/isotronic/homepage/products/ampoules_alt_cKyO284jy.png?updatedAt=1637923776233',
          page: "Ampoules"
        },
        {
          name: this.$t('footer.products.syringes.name'),
          alt: this.$t('footer.products.syringes.alt'),
          img: 'https://ik.imagekit.io/isotronic/homepage/products/syringes_Ysx8TEFew.png?ik-sdk-version=javascript-1.4.3&updatedAt=1665578884715',
          page: "Syringes"
        },
        {
          name: this.$t('footer.products.custom.name'),
          alt: this.$t('footer.products.custom.alt'),
          img: 'https://ik.imagekit.io/isotronic/homepage/products/custom_wgC4_dqrpG.png?updatedAt=1635421016472',
          page: "Custom"
        },
      ]
    }
  }
}
</script>

<style lang="sass" scoped>
.products
  background-color: $less-red
  padding: 15px
  @media screen and (min-width: $sm)
    padding: 30px
</style>
