<template>
  <div>
    <slot></slot>
    <Steps :theme="theme" v-if="steps"/>
    <Products v-if="products"/>
  </div>
</template>

<script>
import Steps from '@/components/footer/StepsToStart'
import Products from '@/components/footer/ProductsInspected'

export default {
  components: {
    Steps,
    Products
  },
  props: {
    theme: {
      type: String,
      default: 'dark' //light / dark
    },
    steps: {
      type: Boolean,
      default: true
    },
    products: {
      type: Boolean,
      default: true
    }
  }
}
</script>
