<template>
  <div :class="theme">
    <b-container fluid class="p-0 i-skeleton">
      <b-row
          class="justify-content-center mx-0 i-outer-padding i-vertical-no-photo mx-md-0"
      >
        <b-col cols="12" sm="9" md="12" class="p-0">
          <h2 class="text-uppercase text-left text-sm-center">
            {{$t("footer.steps.header")}}
          </h2>
        </b-col>
        <b-col cols="12" class="p-0">
          <div class="i-ternary text-left text-sm-center mt-2">
            <div class="i-text">{{$t("footer.steps.subheader1")}}</div>
            <div class="i-text mt-2 mt-sm-0">{{$t("footer.steps.subheader2")}}</div>
          </div>
        </b-col>
        <b-col class="mt-4" cols="12" lg="10">
          <router-link tag="span" :to="$i18nRoute({ name: 'Contact'})">
            <b-row class="justify-content-center">
              <b-col class="p-0 pr-md-0 my-2 my-md-0" cols="12" sm="9" md="4">
                <b-card class="left-card card h-100">
                  <b-row>
                    <b-col cols="auto" class="pr-0 step i-font-header">1</b-col>
                    <b-col class="pl-4">
                      <b-row>
                        <b-col cols="12" class="step-title text-uppercase i-primary i-font-header i-sub-header">
                          {{$t("footer.steps.step1.title")}}
                        </b-col>
                        <b-col cols="12" class="i-font-text-light step-description">
                          <i18n
                              path="footer.steps.step1.description"
                          >
                            <template v-slot:contact>
                              <IsoLink link="Contact">
                                {{$t("footer.steps.step1.link")}}
                              </IsoLink>
                            </template>
                          </i18n>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
              <b-col class="p-0 my-2 my-md-0" cols="12" sm="9" md="4">
                <b-card class="center-card card h-100">
                  <b-row>
                    <b-col cols="auto" class="pr-0 step i-font-header">2</b-col>
                    <b-col class="pl-4">
                      <b-row>
                        <b-col cols="12" class="step-title text-uppercase i-primary i-font-header i-sub-header">
                          {{$t("footer.steps.step2.title")}}
                        </b-col>
                        <b-col cols="12" class="i-font-text-light  step-description">
                          {{$t("footer.steps.step2.description")}}
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
              <b-col class="p-0 pl-md-0 my-2 my-md-0" cols="12" sm="9" md="4">
                <b-card class="right-card card h-100">
                  <b-row>
                    <b-col cols="auto" class="pr-0 step i-font-header">3</b-col>
                    <b-col class="pl-4">
                      <b-row>
                        <b-col cols="12" class="step-title text-uppercase i-primary i-font-header i-sub-header">
                          {{$t("footer.steps.step3.title")}}
                        </b-col>
                        <b-col cols="12" class="i-font-text-light step-description">
                          {{$t("footer.steps.step3.description")}}
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
            </b-row>
          </router-link>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import IsoLink from '@/components/utility/SiteLink'

export default {
  components: {
    IsoLink
  },
  props: {
    theme: String
  }
}
</script>

<style lang="sass" scoped>
.dark
  background: linear-gradient(0deg, #E05A5A, #E05A5A), #FFFFFF
  h2
    color: $ternary-color
  .card
    border: none
    @media screen and (min-width: $md)
      box-shadow: 0 0 0 1px $primary-color, 0px 0px 15px -5px rgba(0, 0, 0, 0.77)
    &:hover
      box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.77), 0 0 0 1px $primary-color
      z-index: 1000
      cursor: pointer
      background-color: #FEF8F8
    @media screen and (min-width: $md)
      box-shadow: 0 0 0 1px $primary-color


.light
  background: linear-gradient(0deg, rgba(100, 101, 104, 0.05), rgba(100, 101, 104, 0.05)), #FFFFFF
  h2
    color: $primary-color!important
  .i-text
    color: $secondary-color!important
  .card
    border: none
    box-shadow: 0 0 0 1px $primary-color, 0px 0px 15px -5px rgba(0, 0, 0, 0.77)
    &:hover
      box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.77), 0 0 0 1px $primary-color
      z-index: 1000
      cursor: pointer
      background: #FEF8F8
    @media screen and (min-width: $md)
      box-shadow: 0 0 0 1px $primary-color

.left-card
  border-radius: 30px
  @media screen and (min-width: $md)
    border-radius: 50px 0 0 50px

.right-card
  border-radius: 30px
  @media screen and (min-width: $md)
    border-radius: 0 50px 50px 0

.center-card
  border-radius: 30px
  @media screen and (min-width: $md)
    border-radius: 0

.step
  font-size: 80px
  color: #E05A5A
  line-height: 78px
  @media screen and (min-width: $xl)
    font-size: 100px
    line-height: 100px

.step-title
  font-size: 26px
  @media screen and (min-width: $xl)
    font-size: 30px

.step-description
  font-size: 14px
  @media screen and (min-width: $xl)
    font-size: 18px
</style>
