<template>
  <b-card class="product-card text-center">
    <b-img :src="product" class="product-icon" :alt="alt"></b-img>
    <div class="text-center i-font-header i-primary text-uppercase mt-1 mt-md-2 menu-title">
      <slot></slot>
    </div>
  </b-card>
</template>

<script>
export default {
  props: {
    product: String,
    alt: String
  }
}
</script>
<style lang="sass" scoped>
.product-card
  height: 168px
  width: 168px
  border-radius: 50px
  @media screen and (min-width: $md)
    height: 215px
    width: 215px
  &:hover
    -webkit-box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.77)
    box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.77)
    cursor: pointer

.product-icon
  height: 91px
  @media screen and (min-width: $md)
    height: 120px

.menu-title
  font-size: 23px
  @media screen and (min-width: $md)
    font-size: 26px
</style>
